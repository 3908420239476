import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=e404602c&scoped=true"
import script from "./AboutUs.vue?vue&type=script&lang=js"
export * from "./AboutUs.vue?vue&type=script&lang=js"
import style1 from "@/assets/css/home.css?vue&type=style&index=1&id=e404602c&prod&scoped=true&lang=css&external"
import style2 from "@/assets/css/aboutUs.css?vue&type=style&index=2&id=e404602c&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e404602c",
  null
  
)

export default component.exports