import { render, staticRenderFns } from "./PrincipalPreview.vue?vue&type=template&id=2004225d&scoped=true"
import script from "./PrincipalPreview.vue?vue&type=script&lang=js"
export * from "./PrincipalPreview.vue?vue&type=script&lang=js"
import style0 from "./PrincipalPreview.vue?vue&type=style&index=0&id=2004225d&prod&scoped=true&lang=css"
import style1 from "@/assets/css/home.css?vue&type=style&index=1&id=2004225d&prod&scoped=true&lang=css&external"
import style2 from "@/assets/css/proyects.css?vue&type=style&index=2&id=2004225d&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2004225d",
  null
  
)

export default component.exports