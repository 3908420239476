<template>
  <v-row id="heading-3">
    <v-col cols="12" align="center" class="pt-10">
      <h1
        class="pt-15 primary--text title-font text-h3 text-lg-h3 text-md-h3"
        data-aos="fade-down"
        data-aos-delay="400"
        data-aos-duration="1500"
      >
        G&M
      </h1>
      <h5
        class="support--text body-font pt-8 pb-16 text-h6 text-md-h6"
        data-aos="fade-up"
      >
        {{ $t("lblConozcaNuestraHistoria") }}
      </h5>
    </v-col>
    <v-col cols="12" md="10" lg="8" class="offset-lg-2 offset-md-1">
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'relative_container' : ''">
        <v-col cols="12" md="6">
          <v-img
            max-height="600"
            contain
            :src="'/img/img-nosotros.jpg'"
            data-aos="fade-right"
            data-aos-delay="1200"
            data-aos-duration="1500"
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="6"> </v-col>
        <div
          :class="$vuetify.breakpoint.mdAndUp ? 'historia_div' : ''"
          data-aos="fade-left"
          data-aos-delay="1800"
          data-aos-duration="1500"
        >
          <v-card
            flat
            color="white"
            max-width="500"
            class="mt-10 pa-10 text-left"
          >
            <v-card-text>
              <h3 class="pb-0 mb-0 body-font font-weight-medium">
                <span class="primary--text font-weight-bold">G&M</span>
                {{ $t("lblConozcaNuestraHistoria1") }}
              </h3>
              <h3 class="pt-10 pb-0 mb-0 body-font font-weight-medium">
                {{ $t("lblConozcaNuestraHistoria2") }}
              </h3>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="10"
      lg="8"
      class="offset-lg-2 offset-md-1 mt-16 mt-10 pb-10 mb-16"
    >
      <v-row
        class="pt-10"
        :class="$vuetify.breakpoint.mdAndUp ? 'relative_container' : ''"
      >
        <v-col cols="12" md="6" class="">
          <h1
            class="pt-5 pt-md-15 primary--text title-font text-lg-h2 text-h3 pl-10 pl-md-0"
          >
            {{ $t("lblNuestro") }}
          </h1>
          <h1
            class="pt-3 primary--text title-font text-lg-h2 text-h3 pl-10 pl-md-0"
          >
            {{ $t("lblEquipo") }}
          </h1>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            max-height="600"
            contain
            :src="'/img/img-equipo.jpg'"
            data-aos="fade-left"
            data-aos-delay="2200"
            data-aos-duration="1500"
          >
          </v-img>
        </v-col>
        <div
          :class="$vuetify.breakpoint.mdAndUp ? 'nosotros_div' : ''"
          data-aos="fade-right"
          data-aos-delay="2500"
          data-aos-duration="1500"
        >
          <v-card
            flat
            color="white"
            max-width="500"
            class="mt-10 pa-10 text-left"
          >
            <v-card-text>
              <h3 class="pb-0 mb-0 body-font font-weight-medium">
                {{ $t("lblConozcaNuestraHistoria3") }}
              </h3>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped></style>
<style scoped src="@/assets/css/home.css"></style>
<style scoped src="@/assets/css/aboutUs.css"></style>
